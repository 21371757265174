import LIST from "../../../../components/shared/CRUD/LIST";

const ListeService = () => {
  return (
    <div>
      <LIST idCategorie={4} idRubrique={17} />
    </div>
  );
};

export default ListeService;
