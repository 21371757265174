import LIST from "../../../../components/shared/CRUD/LIST";

const ListeConsultant = () => {
  return (
    <div>
      <LIST idCategorie={4} idRubrique={15} />
    </div>
  );
};

export default ListeConsultant;
