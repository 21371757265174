import LIST from "../../../../components/shared/CRUD/LIST";

const ListeTravaux = () => {
  return (
    <div>
      <LIST idCategorie={4} idRubrique={18} />
    </div>
  );
};

export default ListeTravaux;
