import LIST from "../../../../components/shared/CRUD/LIST";

const ListeFourniture = () => {
  return (
    <div>
      <LIST idCategorie={4} idRubrique={16} />
    </div>
  );
};

export default ListeFourniture;
